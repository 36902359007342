.leave-reports {
    padding: 20px;
  }
  
  .leave-reports h2 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .leave-reports table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .leave-reports table, .leave-reports th, .leave-reports td {
    border: 1px solid #ccc;
    padding: 10px;
    text-align: center;
  }
  
  .leave-reports th {
    background-color: #f2f2f2;
  }
  