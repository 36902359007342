.reset-password {
    max-width: 400px;
    margin: 50px auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  }
  
  .reset-password h2 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .reset-password form {
    display: flex;
    flex-direction: column;
  }
  
  .reset-password label {
    margin-bottom: 10px;
  }
  
  .reset-password input {
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .reset-password button {
    padding: 10px;
    background-color: #007BFF;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .reset-password button:hover {
    background-color: #0056b3;
  }
  
  .reset-password p {
    text-align: center;
    color: green;
  }
  