.user-dashboard {
    padding: 20px;
  }
  
  .user-dashboard h2 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .leave-requests {
    margin-top: 20px;
  }
  
  .leave-requests h3 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .leave-requests table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .leave-requests table, .leave-requests th, .leave-requests td {
    border: 1px solid #ccc;
    padding: 10px;
    text-align: center;
  }
  
  .leave-requests th {
    background-color: #f2f2f2;
  }
  