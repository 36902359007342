.roles {
    padding: 20px;
  }
  
  .roles h2 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .roles table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .roles table, .roles th, .roles td {
    border: 1px solid #ccc;
    padding: 10px;
    text-align: center;
  }
  
  .roles th {
    background-color: #f2f2f2;
  }
  
  .roles select {
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  