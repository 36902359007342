.login {
    max-width: 400px;
    margin: 50px auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  }
  
  .login h2 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .login form {
    display: flex;
    flex-direction: column;
  }
  
  .login label {
    margin-bottom: 10px;
  }
  
  .login input {
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .login button {
    padding: 10px;
    background-color: #007BFF;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .login button:hover {
    background-color: #0056b3;
  }
  
  .reset-link {
    display: block;
    text-align: center;
    margin-top: 10px;
    color: #007BFF;
    text-decoration: none;
  }
  
  .reset-link:hover {
    text-decoration: underline;
  }
  