.manage-leave-requests {
    padding: 20px;
  }
  
  .manage-leave-requests h2 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .manage-leave-requests table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .manage-leave-requests table, .manage-leave-requests th, .manage-leave-requests td {
    border: 1px solid #ccc;
    padding: 10px;
    text-align: center;
  }
  
  .manage-leave-requests th {
    background-color: #f2f2f2;
  }
  
  .manage-leave-requests button {
    margin: 0 5px;
    padding: 5px 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .manage-leave-requests button:first-of-type {
    background-color: #28a745;
    color: white;
  }
  
  .manage-leave-requests button:last-of-type {
    background-color: #dc3545;
    color: white;
  }
  