.tasks {
    padding: 20px;
  }
  
  .tasks h2 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .tasks form {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .tasks textarea {
    width: 80%;
    height: 100px;
    margin-bottom: 10px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .tasks button {
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .task-list {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .task-item {
    width: 80%;
    background-color: #f9f9f9;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-bottom: 10px;
  }
  