.notification-settings {
    padding: 20px;
  }
  
  .notification-settings h2 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .setting {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .setting label {
    font-size: 16px;
  }
  
  .setting input {
    width: 20px;
    height: 20px;
  }
  