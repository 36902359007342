.leave-history {
    padding: 20px;
  }
  
  .leave-history h2 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .leave-history table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .leave-history table, .leave-history th, .leave-history td {
    border: 1px solid #ccc;
    padding: 10px;
    text-align: center;
  }
  
  .leave-history th {
    background-color: #f2f2f2;
  }
  