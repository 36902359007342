.backup {
    padding: 20px;
  }
  
  .backup h2 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .backup button {
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    display: block;
    margin: 0 auto;
  }
  