.navbar {
    background-color: #333;
    padding: 10px 20px;
  }
  
  .navbar-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .navbar-logo {
    color: white;
    text-decoration: none;
    font-size: 24px;
  }
  
  .navbar-links a {
    color: white;
    text-decoration: none;
    margin-left: 20px;
  }
  
  .navbar-links a:hover {
    text-decoration: underline;
  }
  