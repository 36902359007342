.analytics {
    padding: 20px;
  }
  
  .analytics h2 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .analytics-cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  .analytics-card {
    background-color: #f9f9f9;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 20px;
    margin: 10px;
    text-align: center;
    flex: 1 0 21%; /* Adjust the flex-basis value for card width */
  }
  